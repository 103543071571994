a,
div,
button,
[type="button"] {
  -webkit-appearance: none;
}

.floating {
  display: block;
  margin-left: auto;
  grid-column: 2/3;
  grid-row: auto;
  margin-top: 5.5vw;
  margin-right: 3vw;
  /* padding: 1.5vw; */
}

.floating-button {
  display: grid;
  grid-template-columns: 10% 90%;
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  height: 10.5vw;
  width: 40vw;
  justify-content: flex-end;
  text-align: center;
  align-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  z-index: 100000;
}

.floating-button a {
  position: relative;
  display: block;
  color: #000000;
  font-size: 4.5vw;
  text-align: center;
  text-decoration: none;
  align-self: center;
  margin-left: 5%;
}

.floating-button a:hover {
  color: #ffffff;
  text-decoration: none;
}

.phoneIcon {
  display: grid;
  width: 7vw;
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  margin-left: 40%;
  margin-top: 50%;
  filter: invert();
}

@media screen and (min-width: 1001px) {
  .floating {
    padding: 0vw;
    margin-left: auto;
    margin-top: 0vw;
  }

  .floating-button {
    width: 15vw;
    height: 3vw;
    right: 25%;
    top: 1vw;
  }

  .floating-button a {
    font-size: 1.7vw;
  }

  .phoneIcon {
    width: 2.5vw;
  }
}
