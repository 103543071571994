.video-title {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1fr;
    font-family: "Poppins";
  }

.carousel {
    width: 100%;
    align-self: center;
    margin-top: -2vw;
} 

  @media screen and (min-width: 1001px) {
    .video-title {
        grid-template-rows: none;
      }
    
    .carousel {
        width: 100%;
        align-self: center;
        margin-top: -2vw;
    }
  }