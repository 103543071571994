.home-sales{
    font-family: "Ubuntu", sans-serif;
    font-style: normal;
    width: 100%;
    height: 100%;
    margin-top: -7vw;
}

.home-security-content{
    align-items: center;
    text-align: center;
    position: absolute;
    justify-content: center;
    background-color: white;
    padding: 10% 0% 0% 0%;
    margin: 30% 7% 0% 7%;
    border-radius: 3%;
}

.home-entertainment-content{
    align-items: center;
    text-align: center;
    position: absolute;
    justify-content: center;
    background-color: white;
    padding: 10% 0% 0% 0%;
    margin: 100% 7% 0% 7%;
    border-radius: 3%;
}

.home-security-title{
    text-align: left;
    font-weight: bolder;
    font-size: 1.75rem;
    width: 90%;
    margin: 0% 7% 0% 7%;
    color:#0F2835;
    font-weight: 750;
    line-height: 13vw;
}

.home-entertainment-title{
    text-align: center;
    font-weight: bolder;
    font-family: 'Montserrat';
    font-size: 1.3rem;
    width: 85%;
    margin: 0% 7% 0% 7%;
    color:#02d9a6;
    font-weight: 750;
}

.home-security-sub-title{
    text-align: left;
    font-weight: bolder;
    font-size: 1.25rem;
    width: 90%;
    margin: 7% 7% 5% 7%;
    font-weight: 300;
    color:#0F2835;
}

.home-entertainment-sub-title{
    text-align: left;
    font-family: 'Montserrat';
    font-weight: bolder;
    font-size: 1.2rem;
    width: 90%;
    margin: 5% 7% 0% 7%;
    font-weight: 700;
    color:#0F2835;
    line-height: 8vw;
}

  .home-security-button {
    background-color: #17824A;
    color: #ffffff;
    margin: 7% 0% 0% 0%;
    margin-bottom: 15vw;
    font-weight: 600;
    width: 55%;
    border: 0;
    border-radius: 5px;
    height: 12vw;
    font-size: 6vw;
    filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5));
  }

  .home-entertainment-button {
    background-color: #0F2835;
    font-family: 'Montserrat';
    color: #ffffff;
    margin: 7% 0% 0% 0%;
    margin-bottom: 10vw;
    font-weight: 600;
    width: 80%;
    border: 0;
    border-radius: 40px;
    height: 12vw;
    font-size: 6vw;
    filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5));
  }

@media screen and (min-width: 281px) {
    
    .home-security-title{
        font-size: 2.47rem;
    }
    .home-security-sub-title{
        font-size: 1.35rem;
    }
    
    .home-security-button {
        margin: 5% 0% 0% 0%;
        margin-bottom: 15vw;
    }

    .home-entertainment-content{
        margin: 70% 7% 0% 7%;
        border-radius: 3%;
    }

    .home-entertainment-title{
        font-size: 1.65rem;
    }

    .home-entertainment-sub-title{
        font-size: 1.55rem;
        line-height: 8vw;
        margin: 5% 7% 0% 7%;
    }
}

@media screen and (min-width: 376px) {

    .home-entertainment-content{
        margin: 65% 7% 0% 7%;
        border-radius: 3%;
    }

    .home-entertainment-title{
        font-size: 1.8rem;
    }

    .home-entertainment-sub-title{
        font-size: 1.75rem;
        line-height: 8vw;
        margin: 5% 7% 0% 7%;
    }

}

@media screen and (min-width: 499px) {

    .home-entertainment-title{
        font-size: 2.25rem;
    }

    .home-entertainment-sub-title{
        font-size: 1.9rem;
        line-height: 8vw;
        margin: 5% 7% 0% 7%;
    }

}

@media screen and (min-width: 768px) {
    .home-security-content{
        padding: 8% 0% 0% 0%;
        margin: 17% 14% 0% 14%;
    }
    
    .home-security-title{
        font-size: 3.65rem;
        width: 75%;
        margin: 0% 7% 0% 7%;
        line-height: 11vw;
    }

    .home-security-sub-title{
        font-size: 2.5rem;
        width: 80%;
        margin: 5% 7% 5% 7%;
        font-weight: 300;
        color:#0F2835;
    }
    
    .home-security-button {
        margin-bottom: 10vw;
        height: 11.5vw;
        font-size: 5.5vw;
    }

    .home-entertainment-content{
        margin: 45% 7% 0% 7%;
        border-radius: 3%;
    }

    .home-entertainment-title{
        font-size: 3.5rem;
    }

    .home-entertainment-sub-title{
        font-size: 2.75rem;
        line-height: 7vw;
        margin: 5% 7% 0% 7%;
    }

    .home-entertainment-button {
        margin-bottom: 5vw;
      }
}

@media screen and (min-width: 1001px) {
    .home-sales{
        height: 345%;
        margin-top: 0vw;
    }

    .home-security-content{
        width: 30%;
        padding: 1% 0% 1% 0%;
        margin: 7% 5% 0% 5%;
        border-radius: 1%;
    }

    .home-security-title{
        text-align: left;
        font-weight: bolder;
        font-size: 3.25rem;
        width: 90%;
        margin: 3% 8% 2% 8%;
        color:#0F2835;
        font-weight: 600;
        line-height: 4.5vw;
    }
    .home-security-sub-title{
        text-align: left;
        font-weight: bolder;
        font-size: 1.5rem;
        width: 80%;
        margin: 0% 8% 0% 8%;
        font-weight: 350;
        color:#0F2835;
    }
    .home-security-button {
        background-color: #17824A;
        color: #ffffff;
        margin: 5% 40% 0% 0%;
        margin-bottom: 2vw;
        font-weight: 600;
        width: 45%;
        border: 0;
        border-radius: 5px;
        height: 3.5vw;
        font-size: 1.5vw;
        filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5));
      }

      .home-entertainment-content{
        width: 30%;
        padding: 1% 0% 1% 0%;
        margin: 7% 5% 0% 5%;
        border-radius: 1%;
    }

    .home-entertainment-title{
        text-align: center;
        font-weight: bolder;
        font-family: 'Montserrat';
        font-size: 3rem;
        width: 85%;
        margin: 5% 8% 3% 8%;
        color:#02d9a6;
        font-weight: 750;
    }
    .home-entertainment-sub-title{
        text-align: left;
        font-weight: bolder;
        font-family: 'Montserrat';
        font-size: 2.35rem;
        width: 80%;
        line-height: 3vw;
        margin: 0% 8% 0% 8%;
        font-weight: 700;
        color:#0F2835;
    }
    
      .home-entertainment-button {
        background-color: #0F2835;
        font-family: 'Montserrat';
        color: #ffffff;
        margin: 5% 0% 0% 0%;
        margin-bottom: 2vw;
        font-weight: 600;
        width: 75%;
        border: 0;
        border-radius: 40px;
        height: 3.5vw;
        font-size: 1.5vw;
        filter: drop-shadow(0px 4px 3px rgb(0, 0, 0, 0.5));
      }
}